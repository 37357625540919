import React from "react";
import Img from "gatsby-image";
import { Column } from "../components/columns";
import { Button } from "../components/button/button";

import { Italic, Small, SubTitle, Paragraph } from "../components/text";

import "./employee.sass";

export default ({
  first_name,
  last_name,
  image,
  title,
  department,
  office,
  phone,
  email,
}) => {
  const handleEmailClick = (email) => {
    const emailLink = `mailto:${email}`;
    // document.location.href = emailLink;
  };

  return (
    <Column width="is-one-third">
      <div className="employee">
        <Img
          fluid={{ ...image?.childImageSharp?.fluid, aspectRatio: 1 / 1 }}
          alt={first_name + " " + last_name}
        />
        <div className="employee-info">
          <SubTitle center marginBottom>
            {first_name + " " + last_name}
          </SubTitle>
          <Italic marginBottom>{title}</Italic>
          <Paragraph marginBottom>{`${department}, ${office}`}</Paragraph>
          {phone && phone.length > 0 && (
            <Small marginBottom>
              <a href={`tel:${phone}`} className="link">
                {phone}
              </a>
            </Small>
          )}
          {email && email.length > 0 && (
            <Button
              title="Send email"
              type="submit"
              onClick={() => handleEmailClick(email)}
            />
          )}
        </div>
      </div>
    </Column>
  );
};
