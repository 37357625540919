import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { uniqBy, sortBy, includes } from "lodash";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import { Title } from "../components/text";
import { Columns, Column } from "../components/columns";
import Employee from "./employee";
import Input from "../components/input";
import Select from "../components/select";

export default class Team extends React.Component {
  state = {
    search_string: "",
    selected_department: "",
    selected_office: "",
  };

  onSearch = (event) => {
    this.setState({ search_string: event.target.value });
  };

  onSelectChange = (event) => {
    this.setState({ [`selected_${event.target.name}`]: event.target.value });
  };

  // onOfficeChange = (event) => {
  //   this.setState({ selected_office: event.target.value })
  // }

  // onDepartmentChange = (event) => {
  //   this.setState({ selected_department: event.target.value })
  // }

  getOffices = (employees) => {
    return uniqBy(employees, (o) => o.office).map((o) => o.office);
  };

  getDepartments = (employees) => {
    return uniqBy(employees, (o) => o.department).map((o) => o.department);
  };

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const { frontmatter: content } = data.markdownRemark;

    const { title, image } = content;

    const { search_string, selected_department, selected_office } = this.state;

    let employees_raw = posts
      .filter((post) => post.node.frontmatter.templateKey === "employee")
      .map((employee) => employee.node.frontmatter);

    let employees = sortBy(
      employees_raw,
      (o) => `${o.first_name} ${o.last_name}`
    ).filter((employee) => employee.visible);

    const offices = this.getOffices(employees);

    const departments = this.getDepartments(employees);

    if (
      (search_string && search_string.length > 0) ||
      (selected_department && selected_department.length > 0) ||
      (selected_office && selected_office.length > 0)
    ) {
      employees = employees
        .filter((employee) => {
          const in_first_name = includes(
            employee.first_name.toLowerCase(),
            search_string.toLowerCase()
          );
          const in_last_name = includes(
            employee.last_name.toLowerCase(),
            search_string.toLowerCase()
          );
          const in_title = includes(
            employee.pro_title.toLowerCase(),
            search_string.toLowerCase()
          );
          return in_first_name || in_last_name || in_title;
        })
        .filter((employee) => {
          const is_in_office = includes(
            employee.office.toLowerCase(),
            selected_office.toLowerCase()
          );
          const is_in_department = includes(
            employee.department.toLowerCase(),
            selected_department.toLowerCase()
          );
          return is_in_office && is_in_department;
        });
    }

    return (
      <Page>
        <Helmet title={`Wesmans | ${title}`} />
        <FullWidthImage image={image} height="50vh" parallax={50}>
          <Title bright>{title}</Title>
        </FullWidthImage>
        <Section>
          <Columns>
            <Column width="is-one-quarter">
              <label className="label">Office</label>
              <Select
                options={offices}
                onChange={this.onSelectChange}
                value={selected_office}
                name="office"
              />
            </Column>
            <Column width="is-one-quarter">
              <label className="label">Department</label>
              <Select
                options={departments}
                onChange={this.onSelectChange}
                value={selected_department}
                name="department"
              />
            </Column>
            <Column>
              <Input
                label="Search"
                onChange={this.onSearch}
                placeholder="Search"
                value={search_string}
              />
            </Column>
          </Columns>
        </Section>
        <Section diffBg>
          <Columns multiLine>
            {employees &&
              employees.map((employee) => (
                <Employee
                  key={
                    employee.email + employee.first_name + employee.last_name
                  }
                  first_name={employee.first_name}
                  last_name={employee.last_name}
                  image={employee.image}
                  title={employee.pro_title}
                  department={employee.department}
                  office={employee.office}
                  phone={employee.phone}
                  email={employee.email}
                />
              ))}
          </Columns>
        </Section>
      </Page>
    );
  }
}

export const pageQuery = graphql`
  query TeamsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            templateKey
            visible
            first_name
            last_name
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            pro_title
            department
            office
            phone
            email
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
